import { useContext } from "react";
import { useTranslation } from "react-i18next";

import DeviceStatusIcon from "../TableItem/CustomValues/DeviceStatusIcon";
import QueueIcon from "../TableItem/CustomValues/QueueIcon";
import TaskStatusIcon from "../TableItem/CustomValues/TaskStatusIcon";
import DesktopLicenseStatusIcon from "../TableItem/CustomValues/DesktopLicenseStatusIcon";
import ScheduleData from "../TableItem/ScheduleData";

import DateTime from "../../DateTime";

import AuthContext from "../../../context/auth/authContext";
import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
    Column,
    COLUMN_NAMES,
    COLUMN_RESOURCES,
    ENDPOINTS,
    InitialColumn,
    NOT_FILTER_NAMES,
    parseResource,
    Resource,
    TABLE_NAMES,
    useDateTime,
    useFileSize,
    useFormattedNumber
} from "../../../shared";

export const useTableFunctions = () => {
    const { user } = useContext(AuthContext);

    const { setQueryParams } = useContext(ParamsContext);

    const { isInnerTable, setInnerTableParams } = useContext(TableContext);

    const { t } = useTranslation();
    const { startOfTime } = useDateTime();
    const { renderFileSize } = useFileSize();
    const { renderFormattedNumber } = useFormattedNumber();

    const addIsActiveToColumns = (columns: InitialColumn[]) =>
        columns.map(column => ({
            ...column,
            isActive: Boolean(column.isDefaultActive)
        }));

    const getIsActiveColumnFromLocalStorage = (
        name: TABLE_NAMES,
        columnName: string,
        defaultValue: boolean
    ) => {
        const isJson = (columnsFromStorage: string) => {
            try {
                JSON.parse(columnsFromStorage);

                if (Object.keys(JSON.parse(columnsFromStorage)).length > 0) {
                    return true;
                } else {
                    localStorage.removeItem(`${name}Columns`);
                    return false;
                }
            } catch (error) {
                localStorage.removeItem(`${name}Columns`);
                return false;
            }
        };

        const columns = localStorage.getItem(`${name}ColumnsV2`);

        if (columns) {
            return isJson(columns)
                ? JSON.parse(columns)[columnName]
                : defaultValue;
        }

        return defaultValue;
    };

    const checkIfAllowedToRender = (columns: InitialColumn[]) =>
        columns.filter(({ isAllowedToRender, roleRequiredToRender }) => {
            const isAllowedInGeneral =
                isAllowedToRender === undefined || isAllowedToRender;

            const isValidUser =
                roleRequiredToRender === undefined ||
                Boolean(user && user.role >= roleRequiredToRender);

            return isAllowedInGeneral && isValidUser;
        });

    const renderColumnsWithIsActive = (
        name: TABLE_NAMES,
        initialColumns: InitialColumn[]
    ) => {
        const columnsAllowedToRender = checkIfAllowedToRender(initialColumns);

        const columnsWithIsActive = addIsActiveToColumns(
            columnsAllowedToRender
        );

        return columnsWithIsActive.map((column: Column) => ({
            ...column,
            isActive: getIsActiveColumnFromLocalStorage(
                name,
                column.name,
                column.isActive
            )
        }));
    };

    const renderStringResource = (
        name: COLUMN_NAMES,
        value: string | number | null,
        customDateTimeFormat: string,
        isTranslatable: boolean
    ) => {
        const dateTimeValues = [
            COLUMN_NAMES.ChangedAt,
            COLUMN_NAMES.CreatedAt,
            COLUMN_NAMES.Expiration,
            COLUMN_NAMES.ExpiresAt,
            COLUMN_NAMES.LastCheckedAt,
            COLUMN_NAMES.LastLoginAt,
            COLUMN_NAMES.LastUsedAt,
            COLUMN_NAMES.SeenAt,
            COLUMN_NAMES.LastStatusUpdate,
            COLUMN_NAMES.UpdatedAt
        ];

        if (value === null || value === "") {
            return "-";
        }

        if (dateTimeValues.includes(name)) {
            const dateTimeFormat = customDateTimeFormat || "L LTS";

            return <DateTime date={String(value)} format={dateTimeFormat} />;
        }

        if (isTranslatable) {
            return name === "field"
                ? t(`General##${String(value).toLowerCase()}`)
                : t(`General##${value}`);
        }

        if (name === COLUMN_NAMES.Size) {
            return renderFileSize(value);
        }

        if (name === COLUMN_NAMES.DevicesCount) {
            return renderFormattedNumber(Number(value));
        }

        return value;
    };

    const renderValue = (
        name: COLUMN_NAMES,
        resource: Resource,
        data: any,
        customDateTimeFormat: string = "L LTS",
        isTranslatable: boolean = false
    ) => {
        switch (resource) {
            case COLUMN_RESOURCES.DeviceStatusIcon: {
                return (
                    <DeviceStatusIcon
                        status={data[COLUMN_RESOURCES.DeviceStatusIcon]}
                    />
                );
            }
            case COLUMN_RESOURCES.QueueIcon: {
                return <QueueIcon status={data[COLUMN_RESOURCES.QueueIcon]} />;
            }
            case COLUMN_RESOURCES.TaskStatusIcon: {
                const { verify_attempt_count } = data;

                return (
                    <TaskStatusIcon
                        id={data[COLUMN_RESOURCES.TaskStatusIcon]}
                        verifyAttemptCount={verify_attempt_count}
                    />
                );
            }
            case COLUMN_RESOURCES.DesktopLicenseStatusIcon: {
                return (
                    <DesktopLicenseStatusIcon
                        isEnabled={
                            data[COLUMN_RESOURCES.DesktopLicenseStatusIcon]
                        }
                    />
                );
            }
            case COLUMN_RESOURCES.Schedule: {
                return <ScheduleData data={data} />;
            }
            default: {
                const parsedValue = parseResource(resource, data);

                return renderStringResource(
                    name,
                    parsedValue,
                    customDateTimeFormat,
                    isTranslatable
                );
            }
        }
    };

    const handlePageChange = (newPage: number) => {
        const pageValue = newPage === 1 ? "" : String(newPage);
        const pageQueryParam = { [NOT_FILTER_NAMES.Page]: pageValue };

        isInnerTable
            ? setInnerTableParams({ params: pageQueryParam })
            : setQueryParams({
                  params: pageQueryParam,
                  resetPage: false,
                  removeSelected: true
              });
    };

    const showScheduleTime = (minutes: number) =>
        startOfTime("day").add(minutes, "minutes").format("L LTS");

    const scheduleTimeFrom = (data: any) =>
        data?.schedule
            ? showScheduleTime(data.schedule.attributes.from_minutes)
            : null;

    const scheduleTimeTo = (data: any) =>
        data?.schedule
            ? showScheduleTime(
                  data.schedule.attributes.from_minutes +
                      data.schedule.attributes.duration_minutes
              )
            : null;

    const getEditRowEndpoint = (tableName: TABLE_NAMES, id: number) => {
        const isDesktopLicensesTable =
            tableName === TABLE_NAMES.DesktopLicenses;

        return `${isDesktopLicensesTable ? ENDPOINTS.DesktopLicenses : tableName}/${id}`;
    };

    const handleEditRowItemData = (
        itemData: any,
        isInnerTable: boolean,
        isSubscribed: boolean,
        updateSingleRowData: (itemData: any) => void,
        setBlink: (value: boolean) => void,
        setReloadItems: (resource: TABLE_NAMES | TABLE_NAMES[]) => void
    ) => {
        if (isSubscribed) {
            updateSingleRowData(itemData);
            setBlink(true);

            if (!isInnerTable) {
                setReloadItems([TABLE_NAMES.Tasks, TABLE_NAMES.Changes]);
            }
        }
    };

    return {
        renderColumnsWithIsActive,
        renderValue,
        handlePageChange,
        scheduleTimeFrom,
        scheduleTimeTo,
        getEditRowEndpoint,
        handleEditRowItemData
    };
};
