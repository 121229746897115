/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import { RetryFailedTasksProps } from "./types";

import TasksActionContent from "../TasksActionsContent";

import DialogActions from "../../components/DialogActions";

import Dialog from "../../../../DesignComponents/Dialog";

import AlertContext from "../../../../../context/alert/alertContext";
import {
    BackgroundActionData,
    ENDPOINTS,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../shared";

const RetryFailedTasks = ({
    data,
    isOpen,
    taskGroupId,
    close,
    closeMenu
}: RetryFailedTasksProps) => {
    const { hasCloseBtn, hasAutoHide, removeAlert, setBackgroundActionId } =
        useContext(AlertContext);

    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();
    const { postData, handleResponse } = useApi();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        closeMenu && isOpen && closeMenu();
    }, [isOpen, closeMenu]);

    const handleSubmitRetryTasks = async () => {
        !hasCloseBtn && !hasAutoHide && removeAlert();

        try {
            setLoading(true);

            const { data }: { data: BackgroundActionData } = await postData(
                `${ENDPOINTS.Batches}/${taskGroupId}/retryFailedTasks`
            );

            setBackgroundActionId(
                data,
                () => close(),
                () => setLoading(false)
            );
        } catch (error) {
            handleResponse(error);
            setLoading(false);
        }
    };

    return (
        <Dialog
            isTitleSeparator
            isActionsSeparator
            title={t("Dialog##retry tasks in group##title")}
            description={t("Dialog##retry tasks in group##description")}
            open={isOpen}
            close={isLoading ? undefined : close}
            submit={handleSubmitRetryTasks}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    loaderText={t("Button##retrying")}
                    isCancelDisabled={isLoading}
                    isReadyToConfirm={true}
                    confirmationButtonText={t("Button##accept")}
                />
            }
            TransitionProps={{
                onExited: () => {
                    !hasCloseBtn && !hasAutoHide && removeAlert();
                    setLoading(false);
                }
            }}
            extendToMaxWidth={toMd}
            css={css(fromMd && maxDialogWidth)}
        >
            <TasksActionContent data={data} />
        </Dialog>
    );
};

export default RetryFailedTasks;
