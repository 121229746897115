import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { DropdownValue, EditInlineDialogProps } from "../../../types";
import DialogActions from "../../../components/DialogActions";

import Dialog from "../../../../../DesignComponents/Dialog";
import CompanyDropdown from "../../../../../QueryDropdown/components/CompanyDropdown";
import Autocomplete from "../../../../../DesignComponents/Autocomplete";

import AuthContext from "../../../../../../context/auth/authContext";
import {
    useApi,
    useMediaQueries,
    ENDPOINTS,
    DROPDOWN_SIZES,
    useUserRoles,
    USER_ROLES
} from "../../../../../../shared";

const EditUserInline = ({ data, isOpen, close }: EditInlineDialogProps) => {
    const { isSuperUser } = useContext(AuthContext);

    const { t } = useTranslation();
    const { updateData, handleResponse } = useApi();
    const { toMd } = useMediaQueries();
    const { TRANSLATED_USER_ROLES, getUserRoleInfo } = useUserRoles();

    const [isLoading, setIsLoading] = useState(false);

    const [companyValue, setCompanyValue] = useState<DropdownValue | null>(
        null
    );

    const [roleValue, setRoleValue] = useState<DropdownValue | null>(null);

    useEffect(() => {
        if (data) {
            const {
                company: { id: companyId, name: companyName },
                role
            } = data;

            setCompanyValue({
                id: companyId,
                name: companyName
            });

            if (isSuperUser) {
                const userRoleData = getUserRoleInfo(role as USER_ROLES);
                setRoleValue(userRoleData);
            }
        }

        // eslint-disable-next-line
    }, [data]);

    const handleSubmit = async () => {
        try {
            if (data) {
                setIsLoading(true);

                const payload = {
                    company_id: companyValue?.id,
                    ...(roleValue !== null
                        ? {
                              role: roleValue.id
                          }
                        : {})
                };

                const response = await updateData(
                    ENDPOINTS.Users,
                    data.id,
                    payload
                );

                close();
                handleResponse(response);
            }
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(false);
    };

    const handleCompanyValueChange = (dropdownValue: DropdownValue | null) =>
        setCompanyValue(dropdownValue);

    const handleUserRoleChange = (dropdownValue: DropdownValue) =>
        setRoleValue(dropdownValue);

    return (
        <Dialog
            isTitleSeparator
            isActionsSeparator
            title={t("General##edit user")}
            open={isOpen}
            close={close}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => setCompanyValue(null)
            }}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    isReadyToConfirm={companyValue || roleValue}
                    cancelButtonTestId="cancel-edit-user-inline"
                    confirmationButtonTestId="save-edit-user-inline"
                />
            }
            extendToMaxWidth={toMd}
        >
            <CompanyDropdown
                companyValue={companyValue}
                isLoading={isLoading}
                onChange={(_, val) => handleCompanyValueChange(val)}
                customStyle={{ margin: "8px 0 16px 0" }}
            />

            {isSuperUser && (
                <Autocomplete
                    data-testid="user-role-dropdown"
                    fullWidth
                    disableClearable
                    size={DROPDOWN_SIZES.Medium}
                    labelLeft={t("Table##role")}
                    textFieldParams={{
                        placeholder: t("Dialog##select")
                    }}
                    options={TRANSLATED_USER_ROLES}
                    getOptionLabel={option => option.name}
                    readOnly={isLoading}
                    isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                    }
                    onChange={(_, userRole) => handleUserRoleChange(userRole)}
                    customStyle={{
                        marginBottom: "16px"
                    }}
                    value={roleValue}
                />
            )}
        </Dialog>
    );
};

export default EditUserInline;
