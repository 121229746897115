/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CancelTasksProps } from "./types";

import TasksActionContent from "../TasksActionsContent";

import Dialog from "../../../../DesignComponents/Dialog";
import DialogActions from "../../../../Actions/dialogs/components/DialogActions";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../DesignComponents/BannerMessage";

import AlertContext from "../../../../../context/alert/alertContext";
import {
    BackgroundActionData,
    ENDPOINTS,
    FILTER_NAMES,
    TRI_SOURCES,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../shared";

const CancelTasks = ({
    taskGroupId,
    data,
    isOpen,
    close,
    closeMenu
}: CancelTasksProps) => {
    const { hasCloseBtn, hasAutoHide, removeAlert, setBackgroundActionId } =
        useContext(AlertContext);

    const { t } = useTranslation();
    const { bulkCancel, handleResponse } = useApi();
    const { toMd, fromMd } = useMediaQueries();

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        closeMenu && isOpen && closeMenu();
    }, [isOpen, closeMenu]);

    const handleSubmit = async () => {
        !hasCloseBtn && !hasAutoHide && removeAlert();

        try {
            setLoading(true);

            const payload = {
                source: TRI_SOURCES.Filtered,
                [TRI_SOURCES.Filtered]: [
                    { [FILTER_NAMES.BatchId]: [String(taskGroupId)] }
                ]
            };

            const { data }: { data: BackgroundActionData } = await bulkCancel(
                ENDPOINTS.Tasks,
                payload
            );

            setBackgroundActionId(
                data,
                () => close(),
                () => setLoading(false)
            );
        } catch (error) {
            handleResponse(error);
            setLoading(false);
        }
    };

    return (
        <Dialog
            isTitleSeparator
            isActionsSeparator
            title={t("Dialog##cancel tasks in group##title")}
            description={t("Dialog##cancel tasks in group##description")}
            open={isOpen}
            close={isLoading ? undefined : close}
            submit={handleSubmit}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    loaderText={t("Button##cancelling")}
                    isCancelDisabled={isLoading}
                    isReadyToConfirm={true}
                    confirmationButtonText={t("Button##accept")}
                />
            }
            TransitionProps={{
                onExited: () => {
                    !hasCloseBtn && !hasAutoHide && removeAlert();
                    setLoading(false);
                }
            }}
            extendToMaxWidth={toMd}
            css={css(fromMd && maxDialogWidth)}
        >
            <TasksActionContent data={data} />

            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Warning}
                title={t("Dialog##cancel tasks in group##banner title")}
                css={css({
                    marginBottom: "16px"
                })}
            />
        </Dialog>
    );
};

export default CancelTasks;
