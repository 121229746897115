/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { ATTRIBUTES } from "./types";

import { FILE_NAME } from "../types";

import DialogActions from "../../components/DialogActions";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import Dropzone from "../../../../Dropzone";
import Autocomplete from "../../../../DesignComponents/Autocomplete";
import Dialog from "../../../../DesignComponents/Dialog";
import SampleTemplate from "../../../../DesignComponents/SampleTemplate";

import ThemeContext from "../../../../../context/theme/themeContext";
import AlertContext from "../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../context/params/paramsContext";
import AuthContext from "../../../../../context/auth/authContext";
import DropzoneContext from "../../../../../context/dropzone/dropzoneContext";
import {
    BackgroundActionData,
    DROPDOWN_SIZES,
    ENDPOINTS,
    maxDialogWidth,
    TABLE_NAMES,
    TRI_SOURCES,
    useApi,
    useMediaQueries
} from "../../../../../shared";

const ImportDataFromFile = ({ isOpen, close }: DialogProps) => {
    const {
        colorsFacelift: { textPlaceholder }
    } = useContext(ThemeContext);

    const { hasCloseBtn, hasAutoHide, removeAlert, setBackgroundActionId } =
        useContext(AlertContext);

    const { rootCompany, setReloadItems } = useContext(ParamsContext);
    const { isSupportUser } = useContext(AuthContext);
    const { file, lineCount, removeAllFiles } = useContext(DropzoneContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { toMd, fromMd } = useMediaQueries();

    const [selectedAttribute, setSelectedAttribute] = useState<null | string>(
        null
    );

    const [isLoading, setIsLoading] = useState(false);

    const isReady = selectedAttribute && file && lineCount > 0;

    const handleChangeAttribute = (attr: string) => setSelectedAttribute(attr);

    const getFormData = useCallback(() => {
        const formData = new FormData();

        if (selectedAttribute) {
            formData.append("source", TRI_SOURCES.FromFile);
            formData.append("attribute", selectedAttribute);
            formData.append("file", file as Blob);

            isSupportUser &&
                formData.append("root_id", String(rootCompany?.id as number));
        }

        return formData;
    }, [file, selectedAttribute, isSupportUser, rootCompany?.id]);

    const handleCloseDialog = useCallback(() => {
        close(DEVICE_ACTIONS.ImportDevices);
    }, [close]);

    const handleSubmit = useCallback(async () => {
        !hasCloseBtn && !hasAutoHide && removeAlert();

        if (isReady) {
            try {
                setIsLoading(true);

                const { data }: { data: BackgroundActionData } = await postData(
                    `${ENDPOINTS.Devices}/attributeUpdate`,
                    getFormData()
                );

                setBackgroundActionId(
                    data,
                    () => {
                        handleCloseDialog();
                        setReloadItems(TABLE_NAMES.Devices);
                    },
                    () => setIsLoading(false)
                );
            } catch (error) {
                handleResponse(error);
                setIsLoading(false);
            }
        }
    }, [
        isReady,
        hasCloseBtn,
        hasAutoHide,
        removeAlert,
        getFormData,
        handleCloseDialog,
        handleResponse,
        postData,
        setReloadItems,
        setBackgroundActionId
    ]);

    const limits = {
        [ATTRIBUTES.Description]: 255,
        [ATTRIBUTES.GsmNumber]: 20,
        [ATTRIBUTES.ICCID]: 22,
        [ATTRIBUTES.IMSI]: 15
    };

    return (
        <Dialog
            data-testid="import-device-data-dialog"
            title={t("Dialog##import device data")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={isLoading ? undefined : handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    !hasCloseBtn && !hasAutoHide && removeAlert();
                    setIsLoading(false);
                    removeAllFiles();
                    setSelectedAttribute(null);
                }
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    loaderText={t("Button##importing")}
                    isCancelDisabled={isLoading}
                    isReadyToConfirm={isReady}
                    confirmationButtonText={t("Button##import")}
                    cancelButtonTestId="cancel-import-data"
                    confirmationButtonTestId="import-data-button"
                />
            }
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
        >
            <Dropzone
                supportedFormats={[".csv"]}
                countCsv
                isLoading={isLoading}
                tooltipText={`
                        ${t("Dropzone##file syntax")} 
                        ${t("Dialog##imei")},
                        ${t("Dialog##attribute value")}
                    `}
            />

            <SampleTemplate fileName={FILE_NAME.SampleImportList} />

            <Autocomplete
                data-testid="device-attributes-dropdown"
                isRequired
                labelIcon={t("Dialog##import device data info")}
                options={Object.values(ATTRIBUTES)}
                getOptionLabel={option => t(`Dialog##${option}`)}
                renderOption={(propsData, option: ATTRIBUTES) => {
                    const { key, ...restProps } = propsData;

                    return (
                        <li key={key} {...restProps}>
                            {t(`Dialog##${option}`)}

                            <span
                                css={css({
                                    color: textPlaceholder,
                                    marginLeft: "5px"
                                })}
                            >
                                {t(`Dialog##import device data limit`, {
                                    limit: limits[option]
                                })}
                            </span>
                        </li>
                    );
                }}
                size={DROPDOWN_SIZES.Medium}
                fullWidth
                onChange={(_, val) => handleChangeAttribute(val)}
                labelLeft={t("Table##attribute")}
                readOnly={isLoading}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
            />
        </Dialog>
    );
};
export default ImportDataFromFile;
